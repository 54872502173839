.heritage-header {
    height: 70px;
    display: flex;
    justify-content: space-between;
}

#simple-menu .MuiMenuItem-root:last-child {
    border-bottom: unset;
}
.heritage-appBar {
    height: 70px;
    background-color: transparent !important;
    box-shadow: unset !important;
}
.navigation-btn {
    border-radius: 20px !important;
    padding: 5px 25px !important;
    margin-right: 5px !important;
}
.logout-btn {
    border-radius: 20px !important;
    padding: 5px 25px !important;
    background: transparent !important;
}

@media (max-width: 1200px) {
    .heritage-appBar {
        height: 70px;
        background-color: black !important;
    }
}

.MuiInputBase-root.MuiFilledInput-root.Mui-error {
    border-color: red !important;
}
.MuiFormHelperText-root.Mui-error {
    color: red !important;
}
.modal-footer {
    padding-top: 10px !important;
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: 5px !important;
}
.modal-header {
    color: white !important;
    margin-bottom: 26px !important;
}
.close-button {
    background-color: white !important;
    color: black !important;
    border: white !important;
    margin-right: 10px !important;
}
.close-button:hover {
    background-color: #686767 !important;
}
/*.MuiFormLabel-root {*/
/*    color: rgba(255, 82, 0, 0.8) !important;*/
/*}*/
/*.MuiFormLabel-root.Mui-focused {*/
/*    color: #ff5200 !important;*/
/*}*/
.MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin-bottom: 16px;
}
.MuiSelect-root.MuiSelect-select {
    color: white;
}
.notification-checkbox {
    display: flex;
    align-items: center;
    height: 18px;
    padding-left: 2px;
}
.notification-container {
    display: flex;
    margin-bottom: 8px;
}
@media (max-width: 500px) {
    .notification-container {
        display: block;
        margin-bottom: 0;
    }
    .notification-checkbox {
        margin-bottom: 8px;
    }
}
@media (max-width: 400px) {
    .MuiSelect-root.MuiSelect-select {
        max-width: 250px;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

*::-webkit-scrollbar-track {
    background: #000000;
}

*::-webkit-scrollbar-thumb {
    background-color: #666666;
    border-radius: 3px;
    border: 3px solid #666666;
}

.app-wrapper-image {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: fixed;
}

.app-wrapper-image:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, #00000029, rgba(1, 1, 1, 0.4));
    opacity: 1;
    z-index: 1;
}
.MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 10px !important;
    padding-bottom: 20px;
}

.react-toast-notifications__container {
    z-index: 100000 !important;
}
.MuiButton-root {
    text-transform: none !important;
}

.logo-img {
    height: 77px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

button.MuiButton-contained.Mui-disabled {
    /*background-color: rgb(129 89 70) !important;*/
    /*border: 1px solid rgb(129 89 70) !important;*/
    /*color: white !important;*/
    /*cursor: not-allowed !important;*/
}

.MuiIconButton-root.Mui-disabled {
    /*color: rgb(142 46 1) !important;*/
}

@media (max-width: 1200px) {
    .logo-img {
        height: 75px;
    }
}

@media (min-height: 800px) and (max-height: 850px) and (min-width: 1201px) {
    .logo-img {
        height: 95px;
    }
}

@media (min-height: 900px) and (min-width: 1201px){
    .logo-img {
        height: 125px;
    }
}

@media (max-width: 768px) {
    .app-wrapper-image {
        background-attachment: fixed;
        background-position: inherit;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: 0;
        position: fixed;
    }
    .MuiButton-startIcon .MuiButton-iconSizeMedium {
        width: 15px;
    }
}
@media (min-width: 500px) and (max-width: 1378px) {
    .app-container {
        max-width: 90% !important;
        font-max-size: smaller;
    }
}
@media (max-width: 350px){
    .icon-button-mobile {
        width: 30px;
    }
}

.no-builds-message {
    /*color: #ff5200;*/
    z-index: 1000;
    position: relative;
    text-align: center;
}

.MuiBackdrop-root {
    background-color: #000000cc !important;
}
